import {Component, ElementRef, OnInit} from '@angular/core';
// import * as careersData from "../../jsonData/careers.json";
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ApplyJobModalComponent} from 'src/app/dialog-modals/apply-job-modal/apply-job-modal.component';


@Component({
  selector: 'app-careers-page',
  templateUrl: './careers-page.component.html',
  styleUrls: ['./careers-page.component.scss']
})


export class CareersPageComponent implements OnInit {

  careers: any;

  constructor(private route: ActivatedRoute, private httpClient: HttpClient, private router: Router, public matDialog: MatDialog, lRef: ElementRef) {
    this.getCareers();
  }


  checkPositionCollapse(event, position) {
    position.isCollapsed = event.target.ariaExpanded !== 'true';
  }

  getCareers() {
    const url = 'https://babacasinobucket.s3-us-west-2.amazonaws.com/careers/careers.json';
    this.httpClient.get<any>(url).subscribe(
      (data) => {
        this.careers = data;
      },
      (error) => {
        console.warn('There was an error!', error);
      }
    );
  }

  //form messages
  openApplyJobModal(careerObj) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'applyJobModal';
    dialogConfig.data = {
      careerObj: careerObj
    };
    this.matDialog.open(ApplyJobModalComponent, dialogConfig);
  }

  ngOnInit(): void {


  }

}
