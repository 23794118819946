<app-header></app-header>

<section class="section-image">
  <div>
    <img src="../../../assets/images/home/image.jpg">
  </div>
</section>

<section id="ourStorySection" class="our-story">
  <div class="story-content">
    <div class="story-image">
      <img
        data-aos="zoom-in"
        class="challenges img-fluid" src="../../../assets/images/home/OUR STORY.png"
        alt="Our Story">
    </div>
    <div class="story-text story-left">
      <h3 class="baloo-2-extra-bold">Our Story</h3>
      <p class="story-p baloo-2-semi-bold">
        Baba Entertainment is a growing gaming house which was founded during 2016 with the idea of creating
        something unique and beautiful from scratch. Since then, the company successfully launched its first game on web,
        iOS and Android, while building a growing community of millions of players globally. We're based in the heart
        of the business center of Tel Aviv and always welcome new members to our family. We're looking for the next
        Babas.
      </p>
    </div>
  </div>

  <div class="our-games-border"></div>

  <div class="story-content story-content-2">
    <div class="story-text story-right">
      <h3 class="h3-right baloo-2-extra-bold">Our Vision</h3>
      <p class="story-p baloo-2-semi-bold">
        We believe that everyone should love what they do. We believe that passion is the real drive in life. This is our DNA, this what makes us get up every day and build amazing games with the most unique group of people - people that are striving for a change, people that want to take part of a change, a great change. We call them - Babas.
      </p>
    </div>
    <div class="story-image">
      <img
        data-aos="zoom-in"
        data-aos-delay="500"
        class="tony img-fluid" src="../../../assets/images/home/OUR VISION.png" alt="Our Vision">
    </div>
  </div>
</section>

<img class="waves-1" src="../../../assets/images/home/Path-157.png">

<section id="ourGamesSection" class="our-games">
  <div class="our-games-title">
    <img src="../../../assets/images/home/OUR GAMES.png">
  </div>
  <div class="our-games-section  our-games-section-rev">
    <div class="our-games-text">
      <div class="our-games-text-inner">
        <h3 class="baloo-2-extra-bold">match mania</h3>
        <p class="baloo-2-semi-bold p-o-1">
          Explore the Excitement<br>
          Match, Build, Attack, Steal & Play with your friends! Explore the vast world of Match Mania, a free-to-play game available on iOS and Android. This isn't your typical match 3 game, it's MATCH more!
        </p>
        <div class="social-buttons">
          <a href="https://apps.apple.com/us/app/baba-wild-slots-vegas-casino/id1255614656?l=en" target="_blank">
            <img src="../../../assets/images/home/App-store.png">
          </a>
          <a class="social-btn" href="https://play.google.com/store/apps/details?id=com.bws&hl=en" target="_blank">
            <img src="../../../assets/images/home/Google-play.png">
          </a>
        </div>
      </div>
    </div>

    <div class="our-games-img">
      <img data-aos="zoom-in" class="tablet-mobile-art float-left img-fluid"
           src="../../../assets/images/home/Match_Mania_image.png"
           alt="match mania">
    </div>
  </div>
  <div class="our-games-section">
    <div class="our-games-img ">
      <img data-aos="zoom-in" class="tablet-mobile-art float-left img-fluid"
           src="../../../assets/images/home/Baba_Wild_Slot_image.png"
           alt="match mania">
    </div>
    <div class="our-games-text">
      <div class="our-games-text-inner">
        <h3 class="baloo-2-extra-bold">baba wild slots</h3>
        <p class="baloo-2-semi-bold p-o-2">
          Spin in Lucky slot games, in baba wild slots casino.<br>
          The perfect Las Vegas experience anytime, anywhere, easily<br>
          available on IOS, Android and Facebook.<br>
        </p>
        <div class="social-buttons">
          <a href="https://apps.apple.com/us/app/baba-wild-slots-vegas-casino/id1255614656?l=en" target="_blank">
            <img src="../../../assets/images/home/App-store.png">
          </a>
          <a class="social-btn" href="https://play.google.com/store/apps/details?id=com.bws&hl=en" target="_blank">
            <img src="../../../assets/images/home/Google-play.png">
          </a>
          <a class="social-btn" href="https://www.facebook.com/babawildslots/about" target="_blank">
            <img src="../../../assets/images/home/facebook-play.png">
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<img class="waves-2" src="../../../assets/images/home/Path-157.png">

<section  id="careersSection">
  <div class="careersSection">
    <div class="career-text">
      <div>
        <h3 class="baloo-2-extra-bold">Join the baba family</h3>
        <p class="baloo-2-semi-bold c-1">
          Do you want to be a part of a passionate team in the heart
          of Tel Aviv and work in a rewarding environment with
          other incredibly talented people just like yourself?</p>
        <a routerLink="careers" class="career-btn">
          VIEW JOBS!
        </a>
      </div>
    </div>
    <div class="career-img">
      <img data-aos="zoom-in" class="tablet-mobile-art float-left img-fluid"
           src="../../../assets/images/home/baba team.png"
           alt="baba team">

    </div>
  </div>
</section>


<app-footer></app-footer>








