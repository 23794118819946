<div class="container general-info-page">

  <h2>Baba Entertainment - Responsible Play</h2>

  <section class="mt-3">
    <p>If you wish to self-exclude your account, please find full details on how to do so below</p>
    <p>We encourage you to always enjoy computer games in a safe manner. We have numerous measures as described further below to allow you to engage in our games in a fun and responsible manner.</p>

    <h4>Safe gameplay is when all players:</h4>
    <p>
      <span class="bullet">Have a fun, exciting, and entertaining experience;</span>
      <span class="bullet">Exercise control over their playtime and control over any in-game Gold Coin purchases;</span>
      <span class="bullet">Play in moderation and not to escape reality;</span>
      <span class="bullet">Balance playtime fairly with other real-life activities;</span>
      <span class="bullet">Do not avoid work, family, or other commitments to play games;</span>
      <span class="bullet">Avoid playing if under the effect of alcohol or any illicit substances; and</span>
      <span class="bullet">Avoid playing if they don’t feel comfortable or in control of your activities.</span>
    </p>

    <h4>How can you spot a Computer Game Behavior Disorder?</h4>
    <p>

      <span class="bullet">Take notice of behavioral patterns that are characterized by the following:</span>
      <span class="bullet">An impaired control over playtime;</span>
      <span class="bullet">The need to prioritize gaming over other activities;</span>
      <span class="bullet">An elevated interest in games that surpasses that of other interests or daily responsibilities; or</span>
      <span class="bullet">A consistent escalation of playtime hours despite the occurrence of negative consequences surrounding the player.</span>
      <span class="bullet">It's important to note that for a computer game disorder to be diagnosed, the behavior pattern must be of sufficient severity while resulting in significant impairment of personal, family, social, educational, occupational, or other important areas of functioning, and should normally have been evident for at least 12 months.</span>
    </p>
<h4>What should you do if you or someone you know may have a Computer Game Behavior Disorder?</h4>
   <p>
     <span class="bullet">Seek help from a qualified medical professional</span>
      <span class="bullet">Take some time to research it.</span>
   </p>

    <h4>Learn more about the disorder by reading up on the following materials we've compiled here:</h4>
   <p>
     <span class="bullet"><a href="http://smartsocialgamers.org/2015/07/21/keeping-a-healthy-balance-are-you-playing-too-much/">http://smartsocialgamers.org/2015/07/21/keeping-a-healthy-balance-are-you-playing-too-much/</a></span>
      <span class="bullet"><a href="https://www.npr.org/2019/05/28/727585904/is-gaming-disorder-an-illness-the-who-says-yes-adding-it-to-its-list-of-diseases">https://www.npr.org/2019/05/28/727585904/is-gaming-disorder-an-illness-the-who-says-yes-adding-it-to-its-list-of-diseases</a></span>
      <span class="bullet"><a href="http://smartsocialgamers.org/top-tips/">http://smartsocialgamers.org/top-tips/</a></span>
      <span class="bullet">You can also get access additional advice on Gaming Addicts Anonymous <a href="https://www.gamingaddictsanonymous.org/">https://www.gamingaddictsanonymous.org/</a></span>
    </p>

    <h4>Gold Coin Limits</h4>
   <p>
      <span class="bullet">To avoid spending too much time on computer gaming you can limit how much you can spend on Gold Coin Package(s) per day.</span>
      <span class="bullet">To set a limit please email <a href="mailto:support@babaent.net">support@babaent.net</a> or fill in our Contact Form. We will always try to apply any requested limits as soon as possible, however, the limit may take a reasonable time period to be applied.</span>
      <span class="bullet">If at any point you discover the limit has not been successfully applied, you must immediately inform us to <a href="mailto:support@babaent.net">support@babaent.net</a>.</span>
      <span class="bullet">You can change or revoke the applied limit by emailing <a href="mailto:support@babaent.net">support@babaent.net</a> or by filling in our Contact Form, however, the requested limit will be applied for a minimum of twenty-four hours and no changes will be made until at least twenty-four hours after the initial request.</span>
      <span class="bullet">Again, we will always try to make any changes as soon as possible, however, the change may take a reasonable period to be applied.</span>
   </p>

  </section>
  <section class="mt-3">
    <h3>Self-exclusion terms</h3>

    <h4>What is Self-Exclusion?</h4>
    <p>Self-Exclusion means a request initiated by you to limit access to our Services. We will make all reasonable efforts to limit your access to our Baba-Entertainment.com brand.</p>
    <p>Self-Exclusion will result in restriction of access to your User Account and a block placed on your User Account so that, as far as we can prevent it, access to your User Account will be denied. </p>
    <p>Self-Exclusion is not fail-safe and we cannot guarantee that it is. However, we will take reasonable steps to ensure a Self-Exclusion request is implemented correctly.</p>
    <p>Self-Exclusion also requires commitment to the Self-Exclusion by the Player. Players who request a Self-Exclusion must not try and circumvent the Self-Exclusion by registering a new User Account, setting up new emails to register an account or in any way disguising their identify to register a new account.</p>



    <p>We would suggest any Player who requests Self-Exclusion also cease to follow Baba-Entertainment on all social media channels.</p>
    <p>During a Self-Exclusion a Player should not enter any competitions relating to Baba-Entertainment and will not be permitted to win any competition prizes.</p>
    <p>During a Self-Exclusion, not daily login will be allowed and no daily login rewards etc. will be processed.</p>

      <h3>How do I start the Self-Exclusion process?</h3>


      <p>To self-exclude yourself follow these steps:</p>

      <span><b>1. Login To your Account:</b> Login to your account in Baba Casino</span><br>
      <span><b>2. Profile Settings:</b> Navigate to the profile settings on the top right corner.</span><br>
      <span><b>3. Settings:</b> Click on the "Settings" button.</span><br>
      <span><b>4. Self-Exclusion Option: </b> Within Set Playing Limits, Select the self-exclusion option, where you can choose the duration of the exclusion (e.g., six months, five years, or blocked permanently).</span><br>
      <span><b>5. Confirmation: </b> Confirm your self-exclusion request. Once confirmed, your account will be suspended, and you won't be able to access the gaming services during the exclusion period.</span><br>

      <p>For further assistance or detailed instructions, contact their customer support.</p>
      <p>Or</p>
    <p>You can email <a href="mailto:support@babaent.net">support@babaent.net</a> with the subject line Self Exclusion (“Self Exclusion Request”) or select Self Exclusion on our Contact Form. Please provide your full name, address and email address to ensure we can locate your account to implement the self-exclusion request.</p>
    <p>If we cannot locate your account using this information, we may request additional information to verify your account.
    </p>

    <h4>There are two types of self-exclusion that you may request:</h4>
    <p>1) “Take a Break” this is where you would like to take a break from our Services but do not wish to permanently close your account. Your account will be closed for a set period of time. We currently offer “Take a Break” for a period of seven (7), fourteen (14) or thirty (30) days.
    During this time access to your account will be limited for the period of time requested. Once you select a take a break period your account will remain closed for that period of time. It will not be possible to re-open your User Account during this time.
    Once the Take a Break period has expired access to your account will automatically resume unless you email and notify us you do not wish to re-open your User Account.</p>

    <p>2) “Self-Exclusion” this is where you would like to limit the use of our Services more extensively. Self-Exclusion will be for a minimum of six (6) months, however, can extend to any duration required.
      <span class="bullet">During this time you will not be able to access the Services. After the initial six (6) months you can request access to your User Account again by emailing <a href="mailto:support@babaent.net">support@babaent.net</a> and requesting access to your User-Account.</span>
        <span class="bullet">Your request will be logged but access to your account will be subject to a further seven (7) day waiting period for the re-open request. Once this period has expired you will receive an email asking if you would still like to re-activate your account.</span>
        <span class="bullet">We may ask you some questions to ensure that you wish to open your account and that it is the right option for you.</span>
        <span class="bullet">At this point your User Account may be re-opened if you still wish to do so and we are satisfied that it should be re-opened. Re-opening of an account will be at our sole discretion.</span>
        <span class="bullet">If you just wish to close your account but not permanently prevent access to the Services you can email support@Baba-Entertainment.com for account closure.</span>
        <span class="bullet">After your Self Exclusion Request has been processed you will receive a confirmation email to the email address that we determine is valid for your User Account. If you do not receive a confirmation email within seven days of submitting your Self Exclusion Request please immediately email <a href="mailto:support@babaent.net">support@babaent.net</a> to advise your confirmation email has not been received.</span>
    </p>

<h4>How long will it take to complete the Self-Exclusion process?</h4>
    <p>
      <span class="bullet">We will process the Self-Exclusion or Take a Break Request for an account as soon as feasible after verification of the request. You will receive a confirmation email once your request is successfully processed. In the event that you choose to continue using your account during the Self-Exclusion or Take a Break Request period, you are responsible for all activity including purchases of Gold Coins.</span>
        <span class="bullet">We would strongly recommend that you logout of your User Account and cease your access to our social media as soon as you make your request.</span>
    </p>

<h4>Can you change your mind or reverse a Self-Excluded account?</h4>
    <p>
      <span class="bullet">Take a Break cannot be modified during the Take a Break period requested. Once the Take a Break period has concluded your User Account will be re-activated.</span>
        <span class="bullet">Self-Exclusion status cannot be modified or reversed in any manner for a period of six (6) months thereafter you can request reactivation of your User Account subject to a seven (7) day waiting period.</span>
        <span class="bullet">Please do not try to circumvent a Take a Break or Self-Exclusion by registering a new account, setting up new emails to register an account or in any way disguising their identify to register a new account. If you do try and circumvent these player safety tools it may lead to a permanent ban from our Services. We can not be held responsible for any actions or Gold Coin purchases made from any new or additional accounts used to circumvent a Take a Break of Self-Exclusion.</span>
        <span class="bullet">We will not email you to re-activate your account during a Self-Exclusion so it will continue indefinitely until we hear from you.</span>
        <span class="bullet">Notwithstanding the above, we may exclude you from our Services for a definite or indefinite period in our sole discretion if we believe that there are reasons to indicate that it is not in your best interests to have access to our Services or that you have computer gaming.</span>
    </p>

    <h4>What happens to my Virtual Currency during the Self-Exclusion Period?</h4>
    <p>
      <span class="bullet">Your account will remain inactive during the Self-Exclusion or Take a Break Period. If an account is reactivated, Virtual Currencies are reinstated to the account to the previous amount immediately preceding the time of Self-Exclusion.</span>
        <span class="bullet">No refunds or transfer of Virtual Currencies or any other prize redemptions are permitted during the Self-Exclusion Period.</span>
        <span class="bullet">If you have a pending sweepstakes prize redemption and request a Take a Break or Self-Exclusion it will not be processed.</span>
    </p>
    <h4>Will Baba-Entertainment still have my account information during the Self-Exclusion Period?</h4>
    <p>
      <span class="bullet">Baba-Entertainment will retain certain data during the Self-Exclusion Period in accordance with our
        Privacy Policy.</span>
        <span class="bullet">Baba-Entertainment will use reasonable efforts not to send any marketing material via email during the Self Exclusion. However, we cannot guarantee that you will not receive such material in error. In such event, please notify Baba-Entertainment Customer Support support@ Baba-Entertainment.com so we can rectify this.</span>
      <span class="bullet">If you require access to your account history during this time you will need to email <a href="mailto:support@babaent.net">support@babaent.net</a>.com as you will not have access to your User Account.
        If you have any questions, or need a break from playing any of our games, please contact Customer Support.</span>
    </p>
  </section>

</div>
