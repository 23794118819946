<header>
  <nav class="navbar navbar-expand-lg py-0">

    <div class="header-logo">
      <a class="logo" (click)="goToPage(null,'/',$event)">
        <img class="d-block mx-auto" src="../../../assets/images/home/baba logo horisontail.png" alt="logo">
      </a>
    </div>

    <div class="header-menu">
      <div class="header-mobile">
        <img src="assets/images/logos/baba_ent_logo.png" alt="" class="mobile-logo"
             (click)="goToPage(null,'/',$event)">
        <i class="navbar-toggler" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
           aria-expanded="false" aria-label="Toggle navigation"></i>
      </div>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item nav-text" [routerLink]="['/']" [routerLinkActive]="['is-active']"  [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link">Home</a> <!--(click)="goToPage(null,'/',$event)"-->
          </li>
          <li class="nav-item nav-text" [routerLink]="['/careers']" [routerLinkActive]="['is-active']">
            <a class="nav-link">Careers</a>
          </li>
          <div class="nav-items-social-wrapper">
            <li class="nav-item">
              <a target="_blank" class="ml face-link" href="https://www.facebook.com/babaentertainmentltd">
              </a>
            </li>
            <li class="nav-item">
              <a target="_blank" class="ml linked-link" href="https://www.linkedin.com/company/baba-entertainment">
              </a>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </nav>
</header>
