import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private readonly isHomePage: boolean;

  constructor(private router: Router) {
    this.isHomePage = this.checkRoute();
  }

  checkRoute() {
    return this.router.url === '/';
  }

  scrollToElement(elementId) {
    let element = document.getElementById(elementId);
    if(element){
      element.scrollIntoView({behavior: "smooth", block: "start"});
    }
  }

  goToPage(elementId, url, event) {
    // console.warn(event);
    // console.warn(event.srcElement);

    if (this.isHomePage && elementId) {
      this.scrollToElement(elementId);
    } else {
      if (url) {
        url = '/' + url;
        this.router.navigate([url]).then(() => {
          setTimeout(() => {
            this.scrollToElement(elementId);
          }, 200);
        });
      }
    }
  }

  ngOnInit(): void {

  }

}
