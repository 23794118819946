import {Component, OnInit} from '@angular/core';
import * as careersData from "../../jsonData/careers.json";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-career-info-page',
  templateUrl: './career-info-page.component.html',
  styleUrls: ['./career-info-page.component.scss']
})
export class CareerInfoPageComponent implements OnInit {
  careers: any;
  career: any;
  careerId: number;
  private sub: any;

  constructor(private route: ActivatedRoute) {
    this.careers = careersData.default;
  }

  getCareerById(careerId) {
    this.career = this.careers.openPositions.find(career => career.id === careerId);
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.careerId = +params['id'];
      this.getCareerById(this.careerId);
    });
  }

}
