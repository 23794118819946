import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ContactUsPageComponent} from "./pages/contact-us-page/contact-us-page.component";
import {CareersPageComponent} from "./pages/careers-page/careers-page.component";
import {CareerInfoPageComponent} from "./pages/career-info-page/career-info-page.component";
import {TermsPageComponent} from "./pages/terms-page/terms-page.component";
import {PrivacyPolicyComponent} from "./pages/privacy-policy/privacy-policy.component";
import {HomePageComponent} from "./pages/home-page/home-page.component";
import {SweepPrivacyPolicyComponent} from "./pages/sweep/privacy-policy/privacy-policy.component";
import {ResponsiblePlayComponent} from "./pages/sweep/responsible-play/responsible-play.component";
import {SweepTermsPageComponent} from "./pages/sweep/terms-page/terms-page.component";
import {SweepRulesPageComponent} from "./pages/sweep/rules/rules.component";


const routes: Routes = [
    { path: '', component: HomePageComponent },
    { path: 'contact-us', component: ContactUsPageComponent},
    { path: 'careers', component: CareersPageComponent},
    { path: 'terms-of-service', component: TermsPageComponent},
    { path: 'privacy-policy', component: PrivacyPolicyComponent},
    { path: 'careers-info/:id', component: CareerInfoPageComponent},
    { path: 'play-privacy-policy', component: SweepPrivacyPolicyComponent},
    { path: 'play-responsible-play', component: ResponsiblePlayComponent},
    { path: 'play-terms-of-service', component: SweepTermsPageComponent},
    { path: 'play-rules', component: SweepRulesPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
