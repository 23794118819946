<app-header></app-header>
<div class="container">

  <header>
    <h3>{{career.title}}</h3>
  </header>

  <section class="row">
    <div class="col-3">
      <h5>Our company</h5>
    </div>
    <div class="col">
      <p>{{careers.mainDescription}}</p>
    </div>
  </section>
  <!--our company-->

  <section *ngIf="career.requirements" class="row">
    <div class="col-3">
      <h5>Requirements</h5>
    </div>
    <div class="col">
      <ul>
        <li *ngFor="let item of career.requirements">{{item}}</li>
      </ul>
    </div>
  </section>
  <!--requirements-->

  <section *ngIf="career.responsibilities" class="row">
    <div class="col-3">
      <h5>Responsibilities</h5>
    </div>
    <div class="col">
      <ul>
        <li *ngFor="let item of career.responsibilities">{{item}}</li>
      </ul>
    </div>
  </section>
  <!--our responsibilities-->

  <div class="row my-3">
    <div class="col">
      <img class="img-fluid d-block mx-auto btn-actions" src="../../../assets/images/buttons/apply-now.png"
           alt="apply-now">
    </div>
  </div>
  <!--apply position btn-->


</div>
<app-footer></app-footer>
