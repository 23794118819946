<div class="row">
  <div class="col-lg-6 col-md-10 col-xs-12 form-wrapper mx-auto">
    <form [formGroup]="contactUsForm" autocomplete="on" (ngSubmit)="onSubmit()">

      <div class="row">
        <div class="form-group col-md-6">
          <label for="firstName">First Name</label>
          <input id="firstName" type="text" formControlName="firstName" class="form-control"
                 [ngClass]="{'is-invalid': contactUsForm.get('firstName').errors && submitted}"/>

          <control-validate-error [control]="contactUsForm.get('firstName')"
                                  [isSubmitted]="submitted"></control-validate-error>

        </div>
        <div class="form-group col-md-6">
          <label for="lastName">Last Name</label>
          <input id="lastName" type="text" formControlName="lastName" class="form-control"
                 [ngClass]="{ 'is-invalid': contactUsForm.get('lastName').errors && submitted}"/>

          <control-validate-error [control]="contactUsForm.get('lastName')"
                                  [isSubmitted]="submitted"></control-validate-error>
        </div>
      </div>
      <!--first and last-->

      <div class="row">
        <div class="col-md-6">
          <label for="email">Email</label>
          <input id="email" type="text" formControlName="email" class="form-control"
                 [ngClass]="{ 'is-invalid': contactUsForm.get('email').errors && submitted}"/>

          <control-validate-error [control]="contactUsForm.get('email')"
                                  [isSubmitted]="submitted"></control-validate-error>


        </div>

        <div class="col-md-6">
          <label for="emailConfirmation">Confirm Email</label>
          <input id="emailConfirmation" type="text" formControlName="emailConfirmation" class="form-control"
                 [ngClass]="{'is-invalid': contactUsForm.get('emailConfirmation').errors && submitted }"/>

          <control-validate-error [control]="contactUsForm.get('emailConfirmation')"
                                  [isSubmitted]="submitted"></control-validate-error>

          <div *ngIf="contactUsForm.get('emailConfirmation').errors" class="invalid-feedback d-block">
            <div *ngIf="contactUsForm.get('emailConfirmation').errors.mustMatch">Email must match</div>
          </div>

        </div>

      </div>
      <!--Email and Email confirmation-->

      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Issue</label>
            <div class="row mb-3">
              <div class="col-md-5">
                <select formControlName="issue" class="browser-default custom-select custom-select-md"
                        [ngClass]="{ 'is-invalid': contactUsForm.get('issue').errors && submitted}">
                  <option value="" disabled>Choose a subject</option>
                  <option [ngValue]="'Tech Issue'">Tech Issue</option>
                  <option [ngValue]="'Purchase Issue'">Purchase Issue</option>
                  <option [ngValue]="'Stamps & Envelopes'">Stamps & Envelopes</option>
                  <option [ngValue]="'Tournaments'">Tournaments</option>
                  <option [ngValue]="'Challenges'">Challenges</option>
                  <option [ngValue]="'Bonuses & Gifts'">Bonuses & Gifts</option>
                  <option [ngValue]="'Other'">Other</option>
                </select>
                <control-validate-error [control]="contactUsForm.get('issue')"
                                        [isSubmitted]="submitted"></control-validate-error>
              </div>
            </div>
            <textarea formControlName="message" class="form-control" rows="4" cols="50" placeholder="Write your message"
                      [ngClass]="{ 'is-invalid': contactUsForm.get('message').errors && submitted}"></textarea>

            <control-validate-error [control]="contactUsForm.get('message')"
                                    [isSubmitted]="submitted"></control-validate-error>
          </div>
        </div>
      </div>
      <!--Issue and message-->

      <div class="row mb-3">
        <div class="choose-file-row col-md-4">
          <label class="btn btn-primary btn-block pl-0 pr-0" for="fileUpload">Upload files <i class="fa fa-upload"></i></label>
          <input id="fileUpload" class="choose-file-row" type="file" accept=".jpg, .jpeg, .png" name="fileUpload" (change)="onFileSelect($event)">
        </div>
        <div class="col-md-8 pl-0-lg">
          <div class="files-preview">

            <p *ngIf="!uploadedFiles.length" class="preview-note">No files selected</p>


            <div class="row item m-0" *ngFor="let file of uploadedFiles ; let i = index">

              <div class="col-2 p-0 d-flex justify-content-center align-self-center">
                <i class="fa fa-times close-icon justify-content-center" (click)="removeFileFromUploaded(i)"></i>
              </div>

              <div class="col-8 pr-0 d-flex justify-content-start align-self-center">
                <span class="file-name justify-content-center">{{file.name}}</span>
              </div>

              <div class="col-2 pl-0 d-flex justify-content-center">
                <img class="img-fluid" [src]="file.path">
              </div>

            </div>

          </div>
          <p *ngIf="filesError" class="invalid-feedback d-block">{{filesError}}</p>
        </div>
      </div>
      <!--File upload-->

      <div class="form-group mb-4">
        <button class="btn button1 btn-md btn-block" [ngClass]="{'disabled': contactUsForm.invalid}">
          <i *ngIf="submitted &&!isSuccess && contactUsForm.invalid" class="fa fa-spin fa-spinner"></i>
          <span>SUBMIT</span>
        </button>
      </div>
      <!--Send-->

    </form>
  </div>
</div>
