import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef, MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ValidationService} from 'src/app/services/validation/validation.service';
import {MustMatch} from "../../_helpers/must-match.validator";
import {DialogModalComponent} from "../../components/utils/dialog-modal/dialog-modal.component";

@Component({
  selector: 'app-apply-job-modal',
  templateUrl: './apply-job-modal.component.html',
  styleUrls: ['./apply-job-modal.component.scss']
})

export class ApplyJobModalComponent implements OnInit {
  modalId: any;
  careerObj: any;
  modalData: {
    title: '',
    message: ''
  };
  //form

  serverUrl = 'https://api.babawildslots.com/social/contactJobs';
  // serverUrl = 'https://localhost:8443/social/contactJobs';
  applyJobForm: FormGroup;
  submitted = false;
  uploadedFiles: Array<{}> = [];
  filesError: string;
  showLoader: boolean;
  resumeSectionIsValid: boolean;

  constructor(public dialogRef: MatDialogRef<ApplyJobModalComponent>, @Inject(MAT_DIALOG_DATA) data, public matDialog: MatDialog, private formBuilder: FormBuilder, private httpClient: HttpClient,) {
    this.modalId = this.dialogRef.id;
    this.modalData = data;
    this.careerObj = data.careerObj
    //form
    this.filesError = null;
    this.createForm();
    this.listenToFormFields();
  }

  // Actions
  createForm() {
    this.applyJobForm = this.formBuilder.group({
      positionTitle: [this.careerObj.title ? this.careerObj.title : ''],
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100)
        ]
      ],
      lastName: ['',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100)
        ]],
      email: ['', [Validators.required, ValidationService.emailValidator]],
      phone: ['', [
        Validators.required,
        Validators.minLength(10)
      ]],
      message: ['', [
        Validators.minLength(2),
        Validators.maxLength(100)
      ]],
      uploadedFiles: [[]],
      linkedInUrl: ['', [ValidationService.urlValidator]],
      portfolioUrl: ['', [ValidationService.urlValidator]]
    });
  }

  resumeValidator() {
    this.resumeSectionIsValid = false;
    if (this.uploadedFiles.length >= 1) {
      this.resumeSectionIsValid = true;
      return true;
    }
    if (this.applyJobForm.get('portfolioUrl').value || this.applyJobForm.get('linkedInUrl').value) {
      this.resumeSectionIsValid = true;
      return true;
    }
    return false;
  }

  listenToFormFields() {
    this.applyJobForm.get('linkedInUrl').valueChanges.subscribe(val => {
      if (val) {
        this.applyJobForm.get('portfolioUrl').setValidators(null);
        this.applyJobForm.get('portfolioUrl').updateValueAndValidity();
      }
      this.resumeValidator();
    });

    this.applyJobForm.get('portfolioUrl').valueChanges.subscribe(val => {
      if (val) {
        this.applyJobForm.get('linkedInUrl').setValidators(null);
        this.applyJobForm.get('linkedInUrl').updateValueAndValidity();

      }
      this.resumeValidator();
    });
  }

  getUserLinkedinUrl() {
    return this.httpClient.get('https://api.linkedin.com/v2');
  }

  removeFileFromUploaded(index) {
    this.uploadedFiles.splice(index, 1);
  }

  updateUploadedFiles(file) {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      const binaryString = reader.result as string;
      file.content = window.btoa(binaryString);
      const fileObj = this.setFileObj(file);
      this.uploadedFiles.push(fileObj);
      this.resumeValidator();
    };
  }

  setFileObj(file) {
    let fileObj = {
      filename: String,
      encoding: 'base64',
      content: String
    }
    fileObj.filename = file.name;
    fileObj.content = file.content;
    return fileObj;
  }

  checkFileValidity(fileType, fileSize) {
    var filesCount = this.uploadedFiles.length;
    var isValid = true;
    if (filesCount >= 1) {
      this.filesError = 'Max files limited to 1';
      isValid = false;
    }
    if (fileSize >= 750000) {
      this.filesError = 'Unable to upload files larger than 1 MB';
      isValid = false;
    }
    if (isValid) {
      this.filesError = null;
    } else {
      setTimeout(() => {
        this.filesError = null;
      }, 2500);
    }
    return isValid;
  }

  closeModal() {
    this.dialogRef.close();
  }

  // Events
  ngOnInit(): void {
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      for (let file of event.target.files) {
        if (this.checkFileValidity(file.type, file.size)) {
          this.updateUploadedFiles(file);
        } else {
          return;
        }
      }
    }
  }

  formSuccess() {
    this.submitted = false;
    this.showLoader = false;
    this.uploadedFiles = [];
    this.applyJobForm.reset();
    this.openSuccessModal();
  }

  //form messages
  openSuccessModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'formSuccessModal';
    dialogConfig.data = {
      type: 'success',
      title: 'Form successfully submitted!',
      message: 'Thank you for contacting us. we will get back to you as soon as possible'
    };
    this.matDialog.open(DialogModalComponent, dialogConfig);
  }

  openFailureModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'formFailureModal';
    dialogConfig.data = {
      type: 'failure',
      title: 'Form failed to submit.',
      message: 'Something went wrong please try again later.'
    };
    this.matDialog.open(DialogModalComponent, dialogConfig);
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (!this.applyJobForm.invalid && this.resumeValidator()) {
      this.showLoader = true;

      //adding uploaded files to formData
      const formData = new FormData();
      formData.append('file', this.applyJobForm.get('uploadedFiles').value);
      this.applyJobForm.get('uploadedFiles').setValue(this.uploadedFiles);
      //send an http post

      this.httpClient.post<any>(this.serverUrl, this.applyJobForm.value).subscribe(
        (res) => {
          this.formSuccess();
        },
        (err) => {
          console.warn('err');
          if (err && err.status === 200) {
            this.formSuccess();
          } else {
            // failed
            this.submitted = false;
            this.showLoader = false;
            this.openFailureModal();
          }
        }
      );
    }
  }

}
