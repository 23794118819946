<app-header></app-header>


<div class="container general-info-page">
  <h2>
    Baba Entertainment
    Privacy and Data Protection Policy and
    Notice
  </h2>

  <section class="mt-3">
    <p>
      Baba Entertainment Ltd. (“Baba Entertainment”, “we”, “our” or the “Company”) respects the privacy
      of its gaming platform visitors and registered users and employees, and is committed to protecting
      the personal information you may share with us (these and any others with respect to whom we
      collect personal data, shall collectively be referred to as “user” or “you” or “Data Subjects”).
    </p>

    <p>
      Baba Entertainment provides online social gaming platforms through its mobile and web
      applications and on Facebook (the “Services”).
    </p>

    <p>
      This policy and notice (the “Privacy Policy”) explains the types of information we collect from you,
      that we receive about you or that you may provide in the course of your interest in our Services,
      business transactions, when you visit our websites and participate in our games. We are transparent
      about our practices regarding the information we collect, use, maintain and process and describe
      our practices in this policy and notice. Please read the following carefully to understand our
      practices regarding your personal data and how we will treat it.
    </p>

    <p>
      For the purposes of EU General Data Protection Regulation (the “GDPR”) and other applicable
      privacy laws, Baba Entertainment is a data controller (“Controller”) in relation to the personal data
      of our users, website visitors and employees.
    </p>
  </section>

  <section>
    <h3>1. WHICH INFORMATION MAY WE COLLECT?</h3>

    <p class="italic">
      Summary: we collect various categories of personal data in order to meet our contractual
      obligations, and also to meet various legitimate interests, such as fraud prevention and marketing.
    </p>

    <p>
      We collect data about you in connection with your transactions with us, or in processing data for
      enabling our Services. We also collect data about our employees and online platform visitors. One
      type of data collected is non-identifiable and anonymous information (“non-personal data”). We also
      collect several categories of personal data (“Personal Data”), as described below.
    </p>

    <p>
      Personal Data which is being gathered consists of any details which are personally identifiable and
      which are provided consciously and voluntarily by you, through your registration and use of our
      websites, mobile applications or Facebook gaming platforms (as described below), by email, or other
      ways in which you communicate and interact with us. This generally includes your name (first and
      last), email address, log-in username and password, IP address, country, Facebook identifiers, and
      user activity across Company’s mobile and web applications and Facebook.
    </p>

    <p>
      During your use of our gaming platforms, we also collect data regarding your activity, profiles, and
      in-app purchases.
    </p>

    <p>
      Additionally, we may obtain location data related to the geographic location of your laptop, mobile
      device or other digital device on which the Baba Entertainment gaming platforms are used.
    </p>

    <p>
      You do not have any legal obligation to provide any information to Baba Entertainment, however,
      we require certain information in order to perform contracts, or to provide any Services. If you
      choose not to provide us with certain information, then we may not be able to provide you or your
      organization with some or all of the Services.
    </p>

    <p>
      We also collect your billing information in order to process your payments when conducting in-app
      purchases.
    </p>

    <p>
      By contacting us or submitting requests for information or support via the websites, mobile
      applications or Facebook, email etc., Baba Entertainment will collect details, including also your
      name, phone number and personal or company email you provided, country and other information
      provided by you. Baba Entertainment uses this information to offer Baba Entertainment’s Services
      and support.
    </p>

  </section>


  <section>
    <h3>2. HOW DO WE COLLECT PERSONAL DATA OF YOURS ON BABA ENTERTAINMENT SERVICES?</h3>

    <p class="italic">
      Summary: we collect Personal Data when you send it to us, or when a vendor, distributor or other
      business partner, sends it to us; we collect Personal Data through our website and Services, and
      through our interactions with you.
    </p>

    <p>
      We collect Personal Data required to provide Services when you register interest, or when you
      provide us such information, by entering it manually or automatically, in the course of preparing a
      contract, when using our gaming platforms or otherwise engaging with us. We also collect Personal
      Data when you call us for support, in which case we collect the information you provide us.
    </p>

    <p>
      We also collect Personal Data through your use of our websites, mobile applications or other
      platforms. In other words, when you are using any one of these platforms, we are aware of it and
      may gather, collect and record the information relating to such usage, either independently or
      through the help of third-party services as detailed below. This includes technical information and
      behavioral information such as the user’s Internet protocol (IP) address used to connect your device
      to the Internet, your uniform resource locators (URL), operating system, type of browser, browser
      plug-in types and versions, screen resolution, Flash version, time zone setting, the ‘clickstream’ on
      the platform, and the period of time the user visited the platforms. We likewise may place cookies
      on your browsing devices (see &#39;Cookies&#39; section below).
    </p>
  </section>


  <section>
    <h3>3. WHAT ARE THE PURPOSES OF PERSONAL DATA WE COLLECT?</h3>
    <p class="italic">
      Summary: we process Personal Data to meet our obligations, protect our rights, and manage our
      business.
    </p>

    <p>
      We will use Personal Data to provide and improve our Services to our users and others and meet our
      contractual, ethical and legal obligations. All Personal Data will remain accurate complete and
      relevant for the stated purposes for which it was processed, including for example:
    </p>

    <p>
      Processing which is necessary for the performance of a contract to which you are a party or in order
      to take steps at your request prior to entering into a contract:

      <span class="bullet">
        carrying out our obligations arising from any contracts entered into between you and Baba
        Entertainment and/or any contracts entered into with Baba Entertainment and to provide
        you with the information, gaming elements, support and Services that you request from
        Baba Entertainment;
      </span>

      <span class="bullet">
        verifying and carrying out financial transactions in relation to payments you make in
        connection with the Services.
      </span>
    </p>

    <p>
      Processing which is necessary for the purposes of the legitimate interests pursued by Baba
      Entertainment or by a third party of providing an efficient and wide-ranging service to users:

      <span class="bullet">
        notifying you about changes to our Services and/or to financial products we manage;
      </span>

      <span class="bullet">
        contacting you to give you commercial and marketing information about events or
        promotions or additional products or Services offered by Baba Entertainment which may be
        of interest to you, including in other locations;
      </span>

      <span class="bullet">
        soliciting feedback in connection with the Services;
      </span>

      <span class="bullet">
      tracking use of Baba Entertainment Services to enable us to optimize them.
      </span>

      <span class="bullet">
      for security purposes and to identify and authenticate your access to the login zone.
      </span>

    </p>

    <p>
      Processing which is necessary for compliance with a legal obligation to which Baba Entertainment is
      subject:


      <span class="bullet">
compliance and audit purposes, such as meeting our reporting obligations in our various
jurisdictions, anti money laundering, tax related obligations, and for crime prevention and
prosecution in so far as it relates to our staff, users, service providers, facilities etc;
      </span>

      <span class="bullet">
if necessary, we will use Personal Data to enforce our terms, policies and legal agreements,
to comply with court orders and warrants and assist law enforcement agencies as required
by law, to collect debts, to prevent fraud, infringements, identity thefts and any other
service misuse, and to take any action in any legal dispute and proceeding;
      </span>

    </p>


  </section>


  <section>
    <h3>SHARING DATA WITH THIRD PARTIES</h3>
    <p class="italic">
      Summary: we share Personal Data with our service providers, partners, and group companies, and
      authorities where required.
    </p>

    <p>
      We transfer Personal Data to:
    </p>

    <p>
      Third Parties. We transfer Personal Data to third parties in a variety of circumstances. We endeavor
      to ensure that these third parties use your information only to the extent necessary to perform their
      functions, and to have a contract in place with them to govern their processing on our behalf. These
      third parties include business partners, suppliers, affiliates, agents and/or sub-contractors for the
      performance of any contract we enter into with you. They assist us in providing the Services we
      offer, processing transactions, fulfilling requests for information, receiving and sending
      communications, analyzing data, providing IT and other support services or in other tasks, from time
      to time. These third parties may also include analytics and search engine providers that assist us in
      the improvement and optimization of our websites and Apps, and our marketing.
    </p>

    <p>
      We periodically add and remove third party providers. At present services provided by third-party
      providers to whom we transfer Personal Data include also the following:
    </p>

    <p>
      - Website and App analytics;<br>
      - Document management and sharing services;<br>
      - Client ticketing and support;<br>
      - On-site and cloud-based database services;<br>
      - CRM software;<br>
      - <span class="red-underline">Marketing, user acquisition and retargeting platforms (such as Bigabid, RTB House
    and others)</span><br>
      - Data security, data backup, and data access control systems;<br>
      - Our lawyers, accountants, and other standard business software and partners.<br>
    </p>

    <p>
      In addition, we will disclose your Personal Data to third parties if some or all of our companies or
      assets are acquired by a third party including by way of a merger, share acquisition, asset purchase
      or any similar transaction, in which case Personal Data will be one of the transferred assets.
      Likewise, we transfer Personal Data to third parties if we are under a duty to disclose or share your
      Personal Data in order to comply with any legal or audit or compliance obligation, in the course of
      any legal or regulatory proceeding or investigation, or in order to enforce or apply our terms and
      other agreements with you or with a third party; or to assert or protect the rights, property, or
      safety of Baba Entertainment, our users, or others. This includes exchanging information with other
      companies and organizations for the purposes of fraud protection and credit risk reduction and to
      prevent cybercrime.
    </p>

    <p>
      For avoidance of doubt, Baba Entertainment may transfer and disclose non-Personal Data to third
      parties at its own discretion.
    </p>
  </section>


  <section>
    <h3>5. WHERE DO WE STORE YOUR DATA?</h3>
    <p class="italic">
      Summary: we store your Personal Data across multiple locations globally
    </p>

    <p>
      We store your Personal Data on servers owned or controlled by Baba Entertainment, or processed
      by third parties on behalf of Baba Entertainment, by reputable cloud-service providers (see the
      following section regarding international transfers).
    </p>
  </section>

  <section>
    <h3>6. INTERNATIONAL DATA TRANSFERS (EU DATA SUBJECTS)</h3>
    <p class="italic">
      Summary: we transfer Personal Data within and to the EEA, USA, Israel and elsewhere, with
      appropriate safeguards in place.
    </p>

    <p>
      EU Personal Data is transferred to, and stored and processed at, a destination outside the European
      Economic Area (EEA). This includes transfer to our headquarters in Israel, a jurisdiction deemed
      adequate by the EU Commission, and to the USA, not currently deemed adequate. Where your Data
      is transferred outside of the EEA, we will take all steps reasonably necessary to ensure that your
      Data is subject to appropriate safeguards, including entering into contracts that require the
      recipients to adhere to data protection standards that are considered satisfactory under EU law and
      other applicable, and that it is treated securely and in accordance with this Privacy Policy. Transfers
      to Israel are made based on an adequacy ruling by the EU Commission. Transfers to the USA are
      made based on the Standard Contractual Clauses published by the EU Commission. For more
      information about these safeguards, please contact us as set forth below.
    </p>

    <p>
      We transfer Personal Data to locations outside of the EEA, including in particular USA and Israel, in
      order to:
    </p>

    <p>
      - store or backup the information;<br>
      - enable us to provide you with the Services and products and fulfill our contract with you;<br>
      - fulfill any legal, audit, ethical or compliance obligations which require us to make that
      transfer;<br>
      - facilitate the operation of our group businesses, where it is in our legitimate interests and
      we have concluded these are not overridden by your rights;<br>
      - to serve our users across multiple jurisdictions; and<br>
      - to operate our affiliates in an efficient and optimal manner.<br>
    </p>
  </section>

  <section>
    <h3>7. DATA RETENTION</h3>
    <p>
      <span>
      Summary: we retain Personal Data according to our data retention policy, as required to meet our
      obligations, protect our rights, and manage our business.</span>
    </p>
    <p>
      Baba Entertainment will retain Personal Data it processes only for as long as required in our view, to
      provide the Services and as necessary to comply with our legal and other obligations, to resolve

      disputes and to enforce agreements. We will also retain Personal Data to meet any audit,
      compliance and business best-practices.
    </p>
    <p>
      Data that is no longer retained will be anonymized or deleted. Likewise, some metadata and
      statistical information concerning the use of our Services are not subject to the deletion procedures
      in this policy and will be retained by Baba Entertainment. We will not be able to identify you from
      this data. Some data may also be retained on our third-party service providers’ servers until deleted
      in accordance with their privacy policy and their retention policy, and in our backups until
      overwritten.
    </p>
  </section>

  <section>
    <h3>8. SERVICES AND WEBSITES DATA COLLECTION AND COOKIES</h3>
    <p class="italic">
      Summary: with your consent, we place cookies on your device. You control our use of cookies through
      a cookie management tool on our websites, or through your device and browser.
    </p>
    <p>
      Baba Entertainment uses cookies, pixel tags and other forms of identification and local storage
      (together referred to as “tags/files” hereunder) to distinguish you from other users of the platform.
      This helps us to provide you with a good user-experience when you browse the platform and also
      allows us to improve our Services.
    </p>
    <p>
      In many cases, these tags/files lead to the use of your device’s processing or storage capabilities.
      Some of these tags/files are set by Baba Entertainment itself, others by third parties; some only last
      as long as your browser session, while others can stay active on your device for a longer period of
      time.
    </p>
    <p>These tags/files can fall into several categories: (i) those that are necessary for functionality or
      Services that you request or for the transmission of communications (functionality tags/files); (ii)
      those that we use to carry out website performance and audience metrics (analytics tags/files) and
      (iii) the rest (tracking across a network of other websites, advertising, etc.) (other tags/files).
    </p>
    <p>
      Internet browsers allow you to change your cookie settings, for example to block certain kinds of
      cookies or files. You can therefore block cookies by activating the setting on your browser that
      allows you to refuse the setting of all or some cookies. However, if you use your browser settings to
      block all cookies, you may not be able to access all or parts of the website, due to the fact that some
      may be functionality cookies. For further information about deleting or blocking cookies, please visit:
      <a href="https://www.aboutcookies.org/how-to-delete-cookies/" target="_blank">https://www.aboutcookies.org/how-to-delete-cookies/</a>
    </p>
    <p>
      Functionality and analytical tags/files do not require your consent. For other tags/files, however, we
      request your consent before placing them on your device. You can allow cookies in your browser
      settings and using our website cookie management too.
    </p>
    <p>
      To consult the list of cookies which we use on our website, please check your browser's settings. Instructions:
      To consult the list of cookies which we use on our website, please check your browser&#39;s settings.
      Instructions:       <a href="https://www.wikihow.com/View-Cookies" target="_blank">https://www.wikihow.com/View-Cookies</a>
    </p>
  </section>

  <section>
    <h3>9. SECURITY AND STORAGE OF INFORMATION</h3>
    <p class="italic">
      Summary: we take data security very seriously, invest in security systems, and train our staff. In the
      event of a breach, we will notify the right people as required by law.
    </p>
    <p>
      We take great care in implementing, enforcing and maintaining the security of the Personal Data we
      process. Baba Entertainment implements, enforces and maintains security measures, technologies
      and policies to prevent the unauthorized or accidental access to or destruction, loss, modification,
      use or disclosure of Personal Data. We likewise take steps to monitor compliance of such policies on

      an ongoing basis. Where we deem it necessary in light of the nature of the data in question and the
      risks to data subjects, we encrypt data. Likewise, we take industry standard steps to ensure our
      gaming platforms and Services are safe.
    </p>
    <p>
      Note however, that no data security measures are perfect or impenetrable, and we cannot
      guarantee that unauthorized access, leaks, viruses and other data security breaches will never occur.
    </p>
    <p>
      Within Baba Entertainment, we endeavor to limit access to Personal Data to those of our personnel
      who: (i) require access in order for Baba Entertainment to fulfill its obligations, including also under
      its agreements, and as described in this Privacy Policy, and (ii) have been appropriately and
      periodically trained with respect to the requirements applicable to the processing, care and handling
      of the Personal Data, and (iii) are under confidentiality obligations as may be required under
      applicable law.
    </p>
    <p>
      Baba Entertainment shall act in accordance with its policies and with applicable law to promptly
      notify the relevant authorities and data subjects in the event that any Personal Data processed by
      Baba Entertainment is lost, stolen, or where there has been any unauthorized access to it, all in
      accordance with applicable law and on the instructions of qualified authority. Baba Entertainment
      shall promptly take reasonable remedial measures.
    </p>
  </section>

  <section>
    <h3>10. DATA SUBJECT RIGHTS</h3>
      <p class="italic">
      Summary: depending on the law that applies to your Personal Data, you may have various data subject rights, such as rights to access, erase, and correct Personal Data, and information rights. We will respect any lawful request to exercise those rights.
      </p>
<p>
      Data subjects with respect to whose data GDPR applies, have rights under GDPR and local laws, including, in different circumstances, rights to data portability, rights to access data, rectify data, object to processing, and erase data. It is clarified for the removal of doubt, that where Personal Data is provided by a user being the data subject's employer, such data subject rights will have to be effected through that user, the data subject’s employer. In addition, data subject rights cannot be exercised in a manner inconsistent with the rights of Baba Entertainment employees and staff, with Baba Entertainment proprietary rights, and third-party rights. As such, job references, reviews, internal notes and assessments, documents and notes including proprietary information or forms of intellectual property, cannot be accessed or erased or rectified by data subjects. In addition, these rights may not be exercisable where they relate to data that is not in a structured form, for example emails, or where other exemptions apply. If processing occurs based on consent, data subjects have a right to withdraw their consent.
</p>
      <p>
      Requesting Data Deletion<br>
      If you wish to delete your account and remove all associated data from our platform, please follow the steps below:

         <li><b>Access Settings:</b> Log into your account, navigate to the settings by clicking on the gear icon in the top right corner of the screen.</li>
          <li><b>Delete Account:</b> In the settings menu, find and click on the "Delete Account" option.</li>
          <li><b>Confirmation:</b> You will be prompted to confirm your decision. If you choose to proceed, click on "Delete Account" again.</li>
          <li><b>Completion:</b> After confirmation, your account will be permanently deleted. You will see a notification confirming that your account and all related data have been successfully removed.</li>
      Please note that once you delete your account, it cannot be recovered, and all data associated with it will be permanently erased.

      </p>
      <p>
      A data subject who wishes to modify, delete or retrieve their Personal Data, may also do so by contacting Baba Entertainment (privacy@baba-ent.com). Note that Baba Entertainment may have to undertake a process to identify a data subject exercising their rights. Baba Entertainment may keep details of such rights exercised for its own compliance and audit requirements. Please note that Personal Data may be either deleted or retained in an aggregated manner without being linked to any identifiers or Personal Data, depending on technical commercial capability. Such information may continue to be used by Baba Entertainment.
      </p>
      <p>Data subjects in the EU have the right to lodge a complaint, with a data protection supervisory authority in the place of their habitual residence. If the supervisory authority fails to deal with a complaint, you may have the right to an effective judicial remedy.</p>

  </section>

  <section>
    <h3>11. GENERAL</h3>
    <p>
      Minors. We do not knowingly collect or solicit information or data from or about children under the
      age of 16 without parental consent, or knowingly allow children under the age of 16 to register for
      Baba Entertainment Services. If you are under 16, do not register or attempt to register for any of
      the Baba Entertainment Services or send any information about yourself to us. If we learn that we
      have collected or have been sent Personal Data from a child under the age of 16 without appropriate
      permissions, we will delete that Personal Data as soon as reasonably practicable without any liability
      to Baba Entertainment. If you believe that we might have collected or been sent information from a
      minor under the age of 16, please contact us at: privacy@baba-ent.com as soon as possible.    </p>
    <p>
      Changes to this Privacy Policy. The terms of this Privacy Policy will govern the use of the Services,
      websites, mobile applications and Facebook and any information collected in connection with them.
      Baba Entertainment may amend or update this Privacy Policy from time to time. The most current
      version of this Privacy Policy will be available at: https://www.baba-entertainment.com/privacy-
      policy. Changes to this Privacy Policy are effective as of the stated “Last Revised” date and your
      continued use of our Services will constitute your active acceptance of the changes to and terms of
      the Privacy Policy.    </p>
    <p>
      Baba Entertainment aims to process only adequate, accurate and relevant data limited to the needs
      and purposes for which it is gathered. It also aims to store data for the time period necessary to
      fulfill the purpose for which the data is gathered. Baba Entertainment only collects data in
      connection with a specific lawful purpose and only processes data in accordance with this Privacy
      Policy. Our policies and practices are constantly evolving and improving, and we invite any
      suggestions for improvements, questions, complaints or comments concerning this Privacy Policy,
      you are welcome to contact us (details below) and we will make an effort to reply within a
      reasonable timeframe.    </p>
    <p>
      Baba Entertainment’s data protection officer (DPO) may be contacted at: privacy@baba-ent.com.    </p>

  </section>


  <p class="center">*&emsp;*&emsp;*&emsp;*&emsp;*</p>
  <p>Last Revised: June 18, 2024</p>
</div>


<app-footer></app-footer>
