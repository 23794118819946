<footer>
  <div>
    <div class="links-wrapper">
      <div class="link-wrapper footer-log-wrapper ">
        <img class="footer-log" src="../../../assets/images/home/baba logo horisontail.png">
        <!--<a href="https://www.facebook.com/babaentertainmentltd" target="_blank">Facebook</a>-->
        <!--<a href="https://www.linkedin.com/company/baba-entertainment" target="_blank">Linkedin</a>-->
        <!--<a href="https://twitter.com/babacasino" target="_blank">Twitter</a>-->
        <!--<a href="">Instagram</a>-->
      </div>
      <div class="link-wrapper main-footer-links">
        <a class="link-foot open-sans-semi-bold" routerLink="/privacy-policy">Privacy Policy</a>
        <a class="link-foot open-sans-semi-bold" routerLink="/terms-of-service">Terms of Condition</a>
        <a class="link-foot open-sans-semi-bold" target="_blank" href="https://www.babaglobal.co/">Baba Global</a>
        <a class="link-foot open-sans-semi-bold" routerLink="/contact-us">Support</a>
      </div>
      <div class="link-wrapper link-social">
        <a target="_blank" href="https://www.linkedin.com/company/baba-entertainment"><img src="../../../assets/images/home/Linkdin_Logo.png" alt="baba casino linkdin link"/></a>
        <a target="_blank" class="ml" href="https://www.facebook.com/babaentertainmentltd"><img src="../../../assets/images/home/Facebook_Logo.png" alt="baba casino facebook link"/></a>
        <a target="_blank" class="ml" href="https://x.com/babacasino"><img src="../../../assets/images/home/X_Logo.png" alt="baba casino twitter link"/></a>
      </div>
      <!--<div class="link-wrapper">-->
      <!--  <h5>Contact Us</h5>-->
      <!--  <a href="mailto:support@babaent.net">support@babaent.net</a>-->
      <!--</div>-->
    </div>

    <div class="copy-rights">
      <div>
        <p class="open-sans-semi-bold">© Baba Entertainment LTD. 2015-2024. All Rights Reserved</p>
      </div>
    </div>
  </div>

</footer>



