<app-header></app-header>

<div class="container app-careers-page">
  <section class="row header">
    <div class="col">
      <h3>Careers</h3>
      <!--<p>-->
      <!--  {{careers.mainDescription}}-->
      <!--</p>-->
    </div>
  </section>

  <section class="row careers-list-wrapper">
    <div *ngIf="careers" class="col">
      <div  *ngFor="let position of careers.openPositions; let i = index" class="row career-item mb-3">

        <div class="col-1 px-0 d-none d-sm-block">
                      <span
                        data-toggle="collapse"
                        [attr.aria-expanded]
                        [attr.data-target]="'#positionRow-'+ position.id"
                        [attr.aria-controlst]="'#positionRow-'+ position.id"
                        (click)="checkPositionCollapse($event, position)"
                        class="read-more">
                    {{!position.isCollapsed ? '+' : '-'}}
            </span>
        </div>
        <!--not in mobile-->

        <div class="col">
          <div class="row item-title">

            <div class="col-1 px-0 d-block d-sm-none">
                      <span data-toggle="collapse"
                            [attr.aria-expanded]
                            [attr.data-target]="'#positionRow-'+ position.id"
                            [attr.aria-controlst]="'#positionRow-'+ position.id"
                            (click)="checkPositionCollapse($event, position)"
                            class="read-more">

                  {{!position.isCollapsed ? '+' : '-'}}

            </span>
            </div>
            <div class="col">
              <h5
                data-toggle="collapse"
                [attr.aria-expanded]
                [attr.data-target]="'#positionRow-'+ position.id"
                [attr.aria-controlst]="'#positionRow-'+ position.id"
                (click)="checkPositionCollapse($event, position)"              >
                {{position.title}}
              </h5>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-10 info-col">
              <p>
                {{position.description}}
              </p>
            </div>
            <div class="col-5 col-sm-2 btn-col justify-content-center align-self-center d-block mx-auto">
              <img
                class="img-fluid d-block mx-auto btn-actions"
                src="../../../assets/images/buttons/apply-now.png" alt="apply-now"
                (click)="openApplyJobModal(position)">
            </div>

            <!--<div class="col-12">-->
            <!--<span data-toggle="collapse"-->
            <!--      [attr.aria-expanded]-->
            <!--      [attr.data-target]="'#positionRow-'+ position.id"-->
            <!--      [attr.aria-controlst]="'#positionRow-'+ position.id"-->
            <!--      (click)="checkPositionCollapse($event, position)"-->
            <!--      class="read-more">-->

            <!--      {{!position.isCollapsed ? '+' : '-'}}-->

            <!--</span>-->
            <!--</div>-->
          </div>

          <div class="row career-info-wrapper mt-3">
            <div class="col">
              <div class="collapse" id="positionRow-{{position.id}}">


                <section *ngIf="position.jobDescription" class="row responsibilities justify-content-center">
                  <div class="col-12 col-sm-3">
                    <h5>Job description</h5>
                  </div>
                  <div class="col-10 col-sm-9">
                    <ul>
                      <li *ngFor="let item of position.jobDescription">{{item}}</li>
                    </ul>
                  </div>
                </section>
                <!--our jobDescription-->

                <section *ngIf="position.responsibilities" class="row responsibilities justify-content-center">
                  <div class="col-12 col-sm-3">
                    <h5>Responsibilities</h5>
                  </div>
                  <div class="col-10 col-sm-9">
                    <ul>
                      <li *ngFor="let item of position.responsibilities">{{item}}</li>
                    </ul>
                  </div>
                </section>
                <!--our responsibilities-->

                <section *ngIf="position.requirements" class="row justify-content-center">
                  <div class="col-12 col-sm-3">
                    <h5>Requirements</h5>
                  </div>
                  <div class="col-10 col-sm-9">
                    <ul>
                      <li *ngFor="let item of position.requirements">{{item}}</li>
                    </ul>
                  </div>
                </section>
                <!--requirements-->


                <section *ngIf="position.niceToHave" class="row responsibilities justify-content-center">
                  <div class="col-12 col-sm-3">
                    <h5>Nice to have</h5>
                  </div>
                  <div class="col-10 col-sm-9">
                    <ul>
                      <li *ngFor="let item of position.niceToHave">{{item}}</li>
                    </ul>
                  </div>
                </section>
                <!--our Nice to have-->

                <div class="row my-3 justify-content-center">
                  <div class="col-5 col-sm-3">
                    <img class="img-fluid d-block mx-auto btn-actions"
                         (click)="openApplyJobModal(position)"
                         src="../../../assets/images/buttons/apply-now.png"
                         alt="apply-now">
                  </div>
                </div>
                <!--apply position btn-->
              </div>
            </div>
          </div>
          <!--expended info-->
        </div>

      </div>
    </div>
  </section>
</div>

<app-footer></app-footer>
