import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/main-component/app.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { ContactUsFormComponent } from './components/contact-us-form/contact-us-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ControlValidateMsgComponent } from './components/utils/validate-msg/control-validate-msg.component.js';

// plugins
import { SlickCarouselModule } from 'ngx-slick-carousel';


//dialog modal
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogModalComponent } from './components/utils/dialog-modal/dialog-modal.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CareersPageComponent } from './pages/careers-page/careers-page.component';
import { CareerInfoPageComponent } from './pages/career-info-page/career-info-page.component';
import { ApplyJobModalComponent } from './dialog-modals/apply-job-modal/apply-job-modal.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';


@NgModule({
  declarations: [
    AppComponent,
    ContactUsPageComponent,
    ContactUsFormComponent,
    ControlValidateMsgComponent,
    DialogModalComponent,
    HomePageComponent,
    HeaderComponent,
    FooterComponent,
    CareersPageComponent,
    CareerInfoPageComponent,
    ApplyJobModalComponent,
    TermsPageComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatButtonModule,
    MatDialogModule,
    SlickCarouselModule,
    BrowserAnimationsModule
  ],
  providers: [
    {provide: 'googleTagManagerId',  useValue: 'GTM-MGHWNZ'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
